<template>
  <p>Processando, aguarde...</p>
</template>

<script>
export default {
  mounted() {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if (window.opener) {
      window.opener.postMessage({
        source: "wellon-comunicante",
        data: params
      });

      window.close();
    }
  }
};
</script>
